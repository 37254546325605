import { useController } from 'react-hook-form';
import clsx from 'clsx';
import type { FormTextAreaProps, TextAreaProps } from './types';
import React from 'react';
const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(({
  label,
  placeholder,
  ...rest
}, ref) => {
  return <div className="flex w-full flex-col">
        <label className="mb-3 text-body-8 font-semibold text-gray-600" htmlFor={rest.id}>
          {label}
        </label>
        <textarea className={clsx('w-full resize-none rounded border border-gray-200 p-3 text-body-8 placeholder:text-body-8 placeholder:text-gray-400 focus:border-primary-500 focus:outline-none')} placeholder={placeholder} rows={5} draggable={false} {...rest} ref={ref} />
      </div>;
});
TextArea.displayName = 'TextArea';
export const FormTextArea = ({
  name,
  ...rest
}: FormTextAreaProps) => {
  const {
    field,
    fieldState: {
      error
    }
  } = useController({
    name
  });
  return <TextArea {...field} {...rest} error={error?.message} data-sentry-element="TextArea" data-sentry-component="FormTextArea" data-sentry-source-file="index.tsx" />;
};
export default TextArea;