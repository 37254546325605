'use client';
import { useMemo } from 'react';
import usePlacesAutocomplete, { HookArgs } from 'use-places-autocomplete';

const useSearchAutocomplete = (args: HookArgs = {}) => {
  const {
    ready,
    init,
    setValue: setGooglePlacesValue,
    suggestions: { data, loading },
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    ...args,
  });

  const options = useMemo(() => {
    if (!ready) return [];

    return (data || []).map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return {
        label: [main_text, secondary_text].filter(Boolean).join(' ') || 'Missing label',
        value: place_id,
      };
    });
  }, [ready, data]);

  return {
    isLoading: loading,
    clearSuggestions,
    setGooglePlacesValue,
    options,
    init,
    ready,
  };
};

export default useSearchAutocomplete;
