import clsx from 'clsx';
import { FilterPillProps } from './types';
export const FilterPill = ({
  isActive,
  label,
  onClick,
  className
}: FilterPillProps) => {
  return <button className={clsx('flex flex-shrink-0 rounded-[50px] border px-4 py-2 text-h9 font-bold', {
    'bg-gray-600': isActive,
    'bg-white': !isActive,
    'border-gray-200': !isActive,
    'border-gray-600': isActive,
    'text-white': isActive,
    'text-gray-600': !isActive,
    'transition duration-150 ease-in-out hover:opacity-60': !isActive
  }, className)} onClick={onClick} data-sentry-component="FilterPill" data-sentry-source-file="index.tsx">
      {label}
    </button>;
};