const MapListItemSkeleton = () => {
  return <div className="mx-auto flex h-[30.5rem] w-full max-w-screen-sm animate-pulse flex-col overflow-hidden rounded-[0.625rem] border border-gray-200 shadow-elevation-4 hover:border-primary-500 lg:max-w-none" data-sentry-component="MapListItemSkeleton" data-sentry-source-file="index.tsx">
      <div className="flex h-3/5 w-full bg-gray-200" />
      <div className="flex h-2/5 flex-col justify-between px-5 py-6">
        <div className="body-5 mb-2 h-4 w-1/2 rounded bg-gray-200 font-bold" />
        <div className="body-7 mb-3 h-4 w-full rounded bg-gray-200 text-gray-500" />
        <div className="mb-5 flex items-center gap-x-2 text-body-8 text-gray-500">
          <div className="h-3 w-full rounded bg-gray-200" />
        </div>
        <div className="flex h-6 w-1/3 !justify-start rounded bg-gray-200 !px-0" />
      </div>
    </div>;
};
export default MapListItemSkeleton;