'use client';

import React, { useCallback, useEffect, useState } from 'react';
import { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { PrismicNextImage } from '@prismicio/next';
import { PrevButton, NextButton, usePrevNextButtons } from './ArrowButtons';
import { TestimonialsCarouselProps } from './types';
const TestimonialsCarousel: React.FC<TestimonialsCarouselProps> = ({
  slides
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [autoPlayProgress, setAutoPlayProgress] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    watchDrag: slides.length > 1
  });
  const onNavButtonClick = useCallback((emblaApi: EmblaCarouselType) => {
    // reset autoplay progress
    setAutoPlayProgress(0);
    const currentIndex = emblaApi.selectedScrollSnap();
    setCurrentSlideIndex(currentIndex);
  }, []);
  const {
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi, onNavButtonClick);
  useEffect(() => {
    if (slides.length < 2) return;
    const interval = setInterval(() => {
      if (emblaApi) {
        // check if autoplay progress is 100%
        if (autoPlayProgress >= 100) {
          emblaApi.scrollNext();
          const currentIndex = emblaApi.selectedScrollSnap();
          setAutoPlayProgress(0);
          setCurrentSlideIndex(currentIndex);
        } else {
          // increment autoplay progress by 2%
          setAutoPlayProgress(currentValue => currentValue + 2);
        }
      }
      // autoplay every 5 seconds
    }, 200);
    return () => clearInterval(interval);
  }, [autoPlayProgress, emblaApi, slides.length]);
  return <section className="relative m-auto max-w-[35rem]" data-sentry-component="TestimonialsCarousel" data-sentry-source-file="index.tsx">
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="ml-[-1rem] flex min-h-[22.5rem] touch-pan-y backface-hidden md:min-h-[18.75rem]">
          {slides.map(({
          name,
          avatar,
          comment,
          profession,
          title
        }) => <div className="flex w-full min-w-0 flex-shrink-0 flex-grow-0 basis-full flex-col pl-4" key={name}>
              <p className="mb-7 text-caption-1 font-bold text-primary-600 lg:mb-9">{title}</p>
              <span className="mb-4 line-clamp-6 text-body-6 font-medium md:line-clamp-4 lg:text-body-4">
                {comment}
              </span>
              <div className="mb-[5.5rem] mt-auto flex flex-row md:mb-0">
                <PrismicNextImage field={avatar} width={56} height={56} className="mr-4 rounded-full" />
                <div className="flex h-14 flex-col justify-center">
                  <span className="mb-1 text-h8 font-bold text-gray-600">{name}</span>
                  <span className="text-caption-2 font-medium text-gray-400">{profession}</span>
                </div>
              </div>
            </div>)}
        </div>
      </div>
      {slides.length > 1 && <div className="absolute bottom-0 left-0 flex h-14 flex-row items-center gap-3 md:left-auto md:right-0">
          <PrevButton onClick={onPrevButtonClick} />
          <span className="flex w-[2.375rem] items-center justify-center text-menu font-medium text-gray-400">
            {currentSlideIndex + 1}/{slides.length}
          </span>
          <NextButton onClick={onNextButtonClick} progress={autoPlayProgress} />
        </div>}
    </section>;
};
export default TestimonialsCarousel;