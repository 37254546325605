import clsx from 'clsx';
import React, { useMemo } from 'react';
import { RowProps } from './types';
const RowSkeleton = ({
  className,
  size
}: RowProps) => {
  const width = useMemo(() => {
    const randomWidth = Math.floor(Math.random() * 50);
    switch (size) {
      case 'sm':
        return randomWidth + 20;
      case 'md':
        return randomWidth + 60;
      case 'lg':
        return randomWidth + 120;
      default:
        return randomWidth + 60;
    }
  }, [size]);
  return <div className={clsx('flex flex-row items-center', className)} data-sentry-component="RowSkeleton" data-sentry-source-file="index.tsx">
      <div className="h-5 rounded-sm bg-gray-300" style={{
      width
    }} />
    </div>;
};
export default RowSkeleton;