'use client';

import clsx from 'clsx';
import { PrismicNextImage } from '@prismicio/next';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import useRWD from '@/hooks/useRWD';
// @ts-expect-error there are no types for this package
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';
import { SliderProps } from './types';
import React, { useState } from 'react';
import { ImageField } from '@prismicio/client';
import { createPortal } from 'react-dom';
const SliderPhoto: React.FC<Pick<SliderProps, 'withLightbox' | 'imageClassName'> & {
  slide: ImageField;
}> = ({
  slide,
  imageClassName,
  withLightbox
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return <div className="relative" data-sentry-component="SliderPhoto" data-sentry-source-file="index.tsx">
      <PrismicNextImage field={slide} className={clsx(' w-full object-cover object-center ', imageClassName, {
      'cursor-pointer': withLightbox
    })} onClick={() => {
      if (!withLightbox) {
        return;
      }
      setIsOpen(true);
    }} data-sentry-element="PrismicNextImage" data-sentry-source-file="index.tsx" />

      {withLightbox && isOpen && createPortal(<Lightbox image={slide.url} onClose={() => setIsOpen(false)} doubleClickZoom={2} allowRotate={false} />, document.body)}
    </div>;
};
const Slider = ({
  items,
  className,
  imageClassName,
  withLightbox
}: SliderProps) => {
  const {
    isMobile
  } = useRWD();
  return <Splide className={clsx('relative w-full', className)} options={{
    arrows: false,
    pagination: false,
    gap: '1.25rem',
    fixedWidth: isMobile ? 270 : 528,
    perMove: 1
  }} draggable={true} data-sentry-element="Splide" data-sentry-component="Slider" data-sentry-source-file="index.tsx">
      {items.map(({
      slide
    }, index) => {
      return <SplideSlide key={slide.id ?? index} className="overflow-hidden rounded-[.625rem] border border-gray-200 bg-white last:mr-5">
            <SliderPhoto slide={slide} withLightbox={withLightbox} imageClassName={imageClassName} />
          </SplideSlide>;
    })}
    </Splide>;
};
export default Slider;