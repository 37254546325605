import clsx from 'clsx';
import OpenClosed from '@/components/molecules/OpenClosed';
import Close from '@/public/assets/svg/close.svg';
import { PrismicImage, PrismicLink } from '@prismicio/react';
import { ROUTES } from '@/routes';
import LinkButton from '@/components/atoms/LinkButton';
import { DirectionsIcon } from '@/public/assets/svg';
import { pushGTMEventForATM } from '@/utils/gtm';
import { ATM_MAP_MARKER_DIRECTIONS_CLICK_EVENT, ATM_MAP_VIEW_STORE_DETAILS_CLICK_EVENT } from '@/utils/gtm/consts';
import { useLang } from '@/hooks/useLang';
import { getPreSellLink } from '@/utils/presell';
import { LocationTooltipContentProps } from './types';
const LocationTooltipContent = ({
  location,
  onClose,
  isBuyAndSell,
  availableCoins
}: LocationTooltipContentProps) => {
  const lang = useLang();
  const presellLink = getPreSellLink(location.id, lang);
  return <>
      <div className="-mx-4 mb-2 flex justify-between border-b border-gray-200 pb-4 lg:-mx-0 lg:border-b-0 lg:pb-0">
        <p className="ml-4 pr-8 text-body-5 font-bold lg:ml-0">{location.name}</p>
        <Close className="absolute right-3 top-3" onClick={onClose} role="button" data-sentry-element="Close" data-sentry-source-file="index.tsx" />
      </div>
      <p className="mb-3 text-body-9 text-gray-500">{location.fullAddress}</p>
      <div className="text-tag">
        <OpenClosed locationDetails={location.location} data-sentry-element="OpenClosed" data-sentry-source-file="index.tsx" />
      </div>
      <div className="my-4">
        <div className="mb-3 flex items-center">
          <span className="pr-2 text-body-8 font-semibold">Buy:</span>
          {availableCoins.buy.map(item => <PrismicLink key={item.ticker} field={item.buy_link} className="-mr-1.5">
              <PrismicImage width={28} height={28} field={item.icon} />
            </PrismicLink>)}
        </div>
        {isBuyAndSell && <div className="flex items-center">
            <span className="pr-2 text-body-8 font-semibold">Sell:</span>
            {availableCoins.sell.map(item => <PrismicLink key={item.ticker} field={item.sell_link} className="-mr-1.5">
                <PrismicImage width={28} height={28} field={item.icon} />
              </PrismicLink>)}
          </div>}
      </div>
      <div className="grid w-full grid-cols-2 gap-3">
        {isBuyAndSell && <LinkButton className="order-3 col-span-2 lg:order-1 lg:col-span-1" href={presellLink}>
            Pre-sell Online
          </LinkButton>}
        <LinkButton className={clsx('order-1 col-span-2 lg:order-2', isBuyAndSell ? 'lg:col-span-1' : 'lg-col-span-2')} variant="outline" href={ROUTES.location(location.location)} onClick={() => {
        pushGTMEventForATM(location, ATM_MAP_VIEW_STORE_DETAILS_CLICK_EVENT);
      }} data-sentry-element="LinkButton" data-sentry-source-file="index.tsx">
          Store details
        </LinkButton>
        <LinkButton className="order-2 col-span-2 whitespace-nowrap lg:order-3" variant="secondary" href={location.googleMapUrl} onClick={() => {
        pushGTMEventForATM(location, ATM_MAP_MARKER_DIRECTIONS_CLICK_EVENT);
      }} data-sentry-element="LinkButton" data-sentry-source-file="index.tsx">
          <DirectionsIcon className="mr-2 min-h-5 min-w-5 text-white" data-sentry-element="DirectionsIcon" data-sentry-source-file="index.tsx" /> Get directions
        </LinkButton>
      </div>
    </>;
};
export default LocationTooltipContent;