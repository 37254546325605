import React from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Location } from '@/utils/types';
import LocationItem from '@/components/molecules/LocationItem';
import useRWD from '@/hooks/useRWD';
import { ITEM_SIZE, ROW_SPACING } from './consts';
import { getItemKey, getListHeight } from './utils';
type Props = {
  locations: Location[];
  isLoading: boolean;
  onChangeLocation: (location: Location) => void;
};
type RowProps = Pick<Props, 'onChangeLocation'> & ListChildComponentProps<Location[]>;
const Row: React.FC<RowProps> = ({
  style,
  data,
  index,
  onChangeLocation
}) => <li style={{
  ...style,
  height: style.height || 0 - ROW_SPACING,
  paddingRight: '0.5rem'
}} data-sentry-component="Row" data-sentry-source-file="index.tsx">
    <LocationItem onChangeLocation={onChangeLocation} item={data[index]} data-sentry-element="LocationItem" data-sentry-source-file="index.tsx" />
  </li>;
const LocationList = ({
  locations,
  onChangeLocation,
  isLoading
}: Props) => {
  const {
    isDesktop
  } = useRWD();
  if (isLoading) {
    return <div className="skeleton h-full" />;
  }
  return <FixedSizeList className="animate-fade-in" itemCount={locations.length} width="100%" height={getListHeight(locations, !!isDesktop)} itemSize={ITEM_SIZE} itemData={locations} innerElementType="ul" itemKey={getItemKey} data-sentry-element="FixedSizeList" data-sentry-component="LocationList" data-sentry-source-file="index.tsx">
      {itemProps => <Row {...{
      ...itemProps,
      onChangeLocation,
      isLoading
    }} />}
    </FixedSizeList>;
};
export default LocationList;