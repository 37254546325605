'use client';

import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { PrismicImage } from '@prismicio/react';
import useSWR from 'swr';
import LinkButton from '@/components/atoms/LinkButton';
import ArrowUp from '@/public/assets/svg/arrow-up.svg';
import ArrowDown from '@/public/assets/svg/arrow-down.svg';
import { getLocationFees } from '@/utils/swrRequests';
import { ROUTES } from '@/routes';
import { useLang } from '@/hooks/useLang';
import { useGetCryptos } from '@/hooks/UseGetCryptos';
import { CryptosCarouselProps } from './types';
import CryptoCarouselItemSkeleton from './SliderItemSkeleton';
const CryptosCarousel: React.FC<CryptosCarouselProps> = ({
  button,
  coins
}) => {
  const lang = useLang();
  const [emblaRef] = useEmblaCarousel({
    loop: true,
    align: 'start'
  }, [Autoplay({
    delay: 4000,
    stopOnInteraction: false
  })]);
  const {
    data: location,
    isLoading: isLoadingLocationFees
  } = useSWR([ROUTES.APILocationsFees, lang], async () => await getLocationFees(lang), {
    refreshInterval: 10000
  });
  const {
    isLoading: isLoadingCryptos,
    data: slides
  } = useGetCryptos({
    lang,
    location,
    coins
  });
  return slides?.error ? <div className="o-container o-container--xl my-8">
      <div className="flex items-center justify-center rounded-lg border border-gray-200 p-8 text-body-7 font-bold">
        {slides?.error}
      </div>
    </div> : <div className="relative">
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="ml-4 flex lg:ml-4">
          {(isLoadingLocationFees || isLoadingCryptos) && Array.from({
          length: 5
        }).map((_, index) => <CryptoCarouselItemSkeleton key={index} />)}
          {slides?.result?.map(slide => {
          return <div className="relative mb-10 mr-5 mt-16 min-w-mobileSlider overflow-visible rounded-[0.625rem] border border-gray-200 px-8 pb-8 pt-9 shadow-elevation-4 lg:mb-12 lg:mt-28 lg:min-w-slider lg:px-7" key={slide.label}>
                <PrismicImage width={48} height={48} className="absolute left-1/2 top-0 z-10 -translate-x-1/2 -translate-y-1/2" field={slide.icon} />
                <p className="pb-1 text-center text-h6 font-bold">{slide.label}</p>
                <p className="pb-6 text-center text-caption-2 font-medium text-gray-500">
                  {slide.ticker}/{slide.currencyCode}
                </p>
                <div className="flex w-full justify-between border-t border-gray-200 py-5">
                  <div className="flex">
                    <ArrowUp /> Buy
                  </div>
                  <span className="text-h8 font-bold">{slide.buyValue}</span>
                </div>
                <div className="flex w-full justify-between border-t border-gray-200 pt-5">
                  <div className="flex">
                    <ArrowDown /> Sell
                  </div>
                  <span className="text-h8 font-bold">{slide.sellValue}</span>
                </div>
              </div>;
        })}
        </div>
        <LinkButton className="mx-auto w-fit px-6 text-center" field={button?.link} variant="outline">
          {button?.text || 'Explore more'}
        </LinkButton>
      </div>
    </div>;
};
export default CryptosCarousel;