import { Simplify } from '@/prismicio-types';
import { Content } from '@prismicio/client';
import { format } from 'date-fns/format';

export const sortItemsByDate = (items: Simplify<Content.ArticlesSliceDefaultItem>[]) => {
  return items.sort((a, b) => {
    if (!a.publishDate || !b.publishDate) {
      return 0;
    }

    return a.publishDate > b.publishDate ? -1 : 1;
  });
};

export const getFilters = (items: Simplify<Content.ArticlesSliceDefaultItem>[]): string[] => {
  const result = [`All (${items.length})`];

  items.forEach((item) => {
    if (!item.publishDate) {
      return;
    }

    const year = format(item.publishDate, 'yyyy');

    if (!result.includes(year)) {
      result.push(year);
    }
  });

  return result;
};

export const filterItemsByYear = (
  items: Simplify<Content.ArticlesSliceDefaultItem>[],
  year: string,
) => {
  if (year.includes('All')) {
    return items;
  }

  return items.filter((item) => {
    if (!item.publishDate) {
      return false;
    }

    return format(item.publishDate, 'yyyy') === year;
  });
};
