import { Content } from '@prismicio/client';

export const filterBlogsByCategory = (
  blogs: Content.BlogPostDocument<string>[],
  category: string,
) => {
  if (category.includes('All')) {
    return blogs;
  }

  return blogs.filter((item) => {
    // @ts-expect-error - TS doesn't know that `tag.category` is a `Content.CategoryDocument<string>`
    const itemCategories = item.data.tags.map((tag) => tag?.category?.data?.title);

    return itemCategories.includes(category);
  });
};
