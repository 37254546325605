import qs from 'qs';
import urlJoin from 'url-join';

import { ROUTES } from '@/routes';
import { Lang } from '@/types/locales';
import { addLocale } from '@/utils/locales';

export const getPreSellLink = (locationId: string, lang: Lang): string => {
  const queryParams = qs.stringify(
    {
      atm: locationId,
    },
    {
      addQueryPrefix: true,
    },
  );

  return urlJoin(addLocale(ROUTES.preSell, lang), queryParams);
};
