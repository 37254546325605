'use client';

import useSWR from 'swr';
import { useMemo } from 'react';

import { ROUTES } from '@/routes';
import { makeRequest } from '@/utils';
import { roundNumber } from '@/utils/currency';

import { ExchangeRateResponse } from './types';

export const useETransferCryptoAmount = (amount: number): number | undefined => {
  const { data } = useSWR(ROUTES.eTransferExchangeRate, async () =>
    makeRequest<ExchangeRateResponse>(ROUTES.eTransferExchangeRate),
  );

  const { BTC = undefined } = data || {};

  return useMemo(() => {
    if (!amount || !BTC) {
      return roundNumber(0, 8);
    }

    return roundNumber(amount / BTC, 8);
  }, [BTC, amount]);
};
