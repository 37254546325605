import React, { ReactNode, ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';
import { ColorVariant } from '@/utils/types';
import { tagStyles } from '@/utils/consts';
type ButtonProps = {
  variant: ColorVariant;
  children: ReactNode;
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;
const Tag: React.FC<ButtonProps> = ({
  variant,
  children,
  className
}) => {
  return <div className={clsx('line-clamp-1 items-center justify-center rounded-md px-2 py-1 text-tag font-bold uppercase child:inline', tagStyles[variant] || '', className)} data-sentry-component="Tag" data-sentry-source-file="index.tsx">
      {children}
    </div>;
};
export default Tag;