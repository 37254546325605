'use client';

import Video from 'react-youtube';
import { YoutubeVideoProps } from './types';
const YoutubeVideo = ({
  videoId,
  ...props
}: YoutubeVideoProps) => {
  return <Video videoId={videoId} {...props} data-sentry-element="Video" data-sentry-component="YoutubeVideo" data-sentry-source-file="index.tsx" />;
};
export default YoutubeVideo;