export const checkIfIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent);

export const disableIosTextFieldZoom = () => {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content') || '';
    const regex = /maximum-scale=[0-9.]+/g;

    if (regex.test(content)) {
      content = content.replace(regex, 'maximum-scale=1.0');
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ');
    }

    el.setAttribute('content', content);
  }
};
