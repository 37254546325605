'use client';

import LinkButton from '@/components/atoms/LinkButton';
import LocationInput from '@/components/molecules/LocationInput';
import { useLang } from '@/hooks/useLang';
import { useLoadGoogleMapsScript } from '@/hooks/useLoadGoogleMapsScript';
import { BannerBackgroundIcon } from '@/public/assets/svg';
import { ROUTES } from '@/routes';
import { Coords } from '@/types/location';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { useState } from 'react';

/**
 * Props for `Banner`.
 */
export type BannerProps = SliceComponentProps<Content.BannerSlice>;

/**
 * Component for "Banner" Slices.
 */
const Banner = ({
  slice
}: BannerProps): JSX.Element => {
  const lang = useLang();
  const [location, setLocation] = useState<Coords>({
    latitude: null,
    longitude: null
  });
  const {
    isLoaded
  } = useLoadGoogleMapsScript();
  return <section data-slice-type={slice.slice_type} data-slice-variation={slice.variation} className="o-container o-container--xl my-44" data-sentry-component="Banner" data-sentry-source-file="index.tsx">
      <div className="relative flex items-center overflow-hidden rounded-[.625rem] bg-gray-50 px-7 py-[8.75rem] md:px-24">
        <div className="opacity-35">
          <div className="absolute right-[-75px] top-[.3125rem] h-[11.25rem] w-[14.375rem] rounded-full bg-primary-650 blur-2xl lg:right-[-10%] lg:top-[-10%] lg:h-[26.25rem] lg:w-[33.75rem] lg:blur-3xl" />
          <div className="absolute right-[-130px] top-[3.125rem] h-[9.5625rem] w-[14.8125rem] rounded-full bg-yellow-500 blur-2xl lg:bottom-0 lg:right-[-15.625rem] lg:top-0 lg:mb-auto lg:mt-auto lg:h-[22.5rem] lg:w-[34.375rem] lg:blur-3xl" />
          <div className="absolute hidden rounded-full bg-secondary-250 opacity-25 blur-3xl lg:right-[-12.5rem] lg:top-[-12.5rem] lg:block lg:h-[31.25rem] lg:w-[31.25rem]" />
        </div>
        <BannerBackgroundIcon className="absolute right-0 top-0 z-30 hidden lg:block" data-sentry-element="BannerBackgroundIcon" data-sentry-source-file="index.tsx" />
        {slice.variation === 'default' && <div className="z-10 flex w-full flex-col md:w-[21.25rem]">
            <h2 className="mb-6 text-h3 font-bold text-gray-600 md:text-title">
              {slice.primary.title}
            </h2>
            <p className="mb-6 text-body-7 text-gray-400">{slice.primary.description}</p>
            <LinkButton field={slice.primary.buttonLink} className="w-full md:w-[10.375rem]">
              <span>{slice.primary.buttonText}</span>
            </LinkButton>
          </div>}
        {slice.variation === 'withLocationInput' && <div className="z-10 flex flex-col">
            <h2 className="mb-6 w-full text-h3 font-bold text-gray-600 md:w-[440px] md:text-title">
              {slice.primary.title}
            </h2>
            <div className="w-dull flex flex-col md:w-[31.875rem] md:flex-row">
              <LocationInput className="mb-6 h-11 w-full md:mb-0" onSelect={setLocation} placeholder="Enter your address or postal code" isGoogleMapsScriptLoaded={isLoaded} />
              <LinkButton href={{
            pathname: ROUTES.bitcoinATM(lang),
            query: {
              latitude: location.latitude,
              longitude: location.longitude
            }
          }} className="w-full md:ml-4 md:w-[10.375rem] md:flex-shrink-0" disabled={!location.latitude || !location.longitude}>
                {slice.primary.buttonText}
              </LinkButton>
            </div>
          </div>}
      </div>
    </section>;
};
export default Banner;