import React, { useMemo } from 'react';
const RowWithImageSkeleton = () => {
  const width = useMemo(() => Math.floor(Math.random() * 50) + 100, []);
  return <div className="flex flex-row items-center" data-sentry-component="RowWithImageSkeleton" data-sentry-source-file="index.tsx">
      <div className="mr-4 h-7 w-7 rounded-full bg-gray-300" />
      <div className="h-5 rounded-sm bg-gray-300" style={{
      width
    }} />
    </div>;
};
export default RowWithImageSkeleton;