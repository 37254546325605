'use client';

import React, { useState } from 'react';
import FaqItem from '../FaqItem';
import { Content } from '@prismicio/client';
import { Simplify } from '@/prismicio-types';
type Props = {
  items: Simplify<Content.FaqSliceDefaultItem>[];
};
const FAQList = ({
  items
}: Props) => {
  const [openedFAQ, setOpenedFaq] = useState<number[]>([]);
  const toggleItem = (index: number) => () => setOpenedFaq(prev => prev.includes(index) ? prev.filter(item => item !== index) : [...prev, index]);
  return <div data-sentry-component="FAQList" data-sentry-source-file="index.tsx">
      {items.map(({
      header,
      copy
    }, index) => <FaqItem toggleItem={toggleItem(index)} isOpen={openedFAQ.includes(index)} header={header} copy={copy} key={header} />)}
    </div>;
};
export default FAQList;