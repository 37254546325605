'use client';

import React, { useMemo } from 'react';
import LinkButton from '@/components/atoms/LinkButton';
import ArrowRightIcon from '@/public/assets/svg/arrow-right.svg';
import TrendUpIcon from '@/public/assets/svg/fees/trend-up.svg';
import TrendDownIcon from '@/public/assets/svg/fees/trend-down.svg';
import { CryptoTableType } from '@/utils/types';
import { PrismicNextImage } from '@prismicio/next';
import { buttonText } from './consts';
const TableBody = ({
  variant,
  rows
}: CryptoTableType) => {
  const centerRows = useMemo(() => {
    return variant == 'buyTable' ? rows?.map(item => {
      return [item.buyValue, item.buyFeeFlat];
    }) || [] : rows?.map(item => {
      return [item.sellValue];
    }) || [];
  }, [rows, variant]);
  return <tbody className="shadow-elevation-3 before:block before:h-4" data-sentry-component="TableBody" data-sentry-source-file="index.tsx">
      {rows?.map((row, index) => {
      return <tr className="h-[4.5rem] border-gray-200 child:h-[inherit] child:p-0 child:child:first:border-t-0 child:first:border-t first:child:pl-6 child:last:border-b last:child:pr-6" key={row.ticker}>
            <td>
              <div className="flex h-full items-center gap-x-3 border-t">
                <PrismicNextImage width={28} height={28} field={row.icon} />
                {`${row.label} (${row.ticker}/${row.currencyCode})`}
              </div>
            </td>
            {centerRows[index]?.map(item => <td key={item}>
                <div className="flex h-full items-center border-t">{item}</div>
              </td>)}
            <td>
              <div className="flex h-full items-center gap-x-3 border-t">
                {row.change > 0.0 ? <TrendUpIcon className="text-green-600" /> : row.change.toFixed(2) != '0.00' && <TrendDownIcon className="text-primary-600" />}
                {row.change.toFixed(2)} %
              </div>
            </td>
            <td className="border-r">
              <div className="h-full border-t !py-3.5">
                <LinkButton variant="outline" className="ml-auto max-w-44" field={variant === 'buyTable' || variant === 'buyFeesAndLimits' ? row.buyLink : row.sellLink}>
                  {buttonText[variant]} {row.ticker} <ArrowRightIcon />
                </LinkButton>
              </div>
            </td>
          </tr>;
    })}
    </tbody>;
};
export default TableBody;