import { Settings } from './types';

export const defaultSettings: Settings = {
  desktopFeatures: {
    autoplay: 4000,
  },
  mobileFeatures: {
    autoplay: 4000,
  },
};
