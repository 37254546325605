'use client';

import { PropsWithChildren } from 'react';
import { useInView } from 'react-intersection-observer';
const RenderInView = ({
  children
}: PropsWithChildren) => {
  const {
    ref,
    inView
  } = useInView({
    threshold: 0.5,
    triggerOnce: true
  });
  return <div ref={ref} data-sentry-component="RenderInView" data-sentry-source-file="index.tsx">{inView && children}</div>;
};
export default RenderInView;