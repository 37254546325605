import { SelectInputItem } from '@/components/atoms/Select/types';

export const StoreTypes: SelectInputItem[] = [
  {
    value: 'Gas Station',
    label: 'Gas Station',
  },
  {
    value: 'Convenience Store',
    label: 'Convenience Store',
  },
  {
    value: 'Liquor Store / Grocery Store / Cafes',
    label: 'Liquor Store / Grocery Store / Cafes',
  },
  {
    value: 'Vape Store / Pizza Store',
    label: 'Vape Store / Pizza Store',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];
