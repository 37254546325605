import React from 'react';
import RowWithImageSkeleton from './partials/RowWithImageSkeleton';
import RowSkeleton from './partials/RowSkeleton';
const DesktopTableSkeleton = () => {
  return <table className="mt-8 hidden w-full animate-pulse lg:table" data-sentry-component="DesktopTableSkeleton" data-sentry-source-file="index.tsx">
      <thead className="mb-4">
        <tr className="mb-4">
          <th className="h-14 w-1/4 rounded-l-lg bg-gray-200 px-6">
            <RowSkeleton size="lg" data-sentry-element="RowSkeleton" data-sentry-source-file="index.tsx" />
          </th>
          <th className="h-14 w-1/4 bg-gray-200 px-6">
            <RowSkeleton data-sentry-element="RowSkeleton" data-sentry-source-file="index.tsx" />
          </th>
          <th className="h-14 w-1/4 bg-gray-200 px-6">
            <RowSkeleton data-sentry-element="RowSkeleton" data-sentry-source-file="index.tsx" />
          </th>
          <th className="h-14 w-1/4 rounded-r-lg bg-gray-200 px-6">
            <RowSkeleton className="justify-end" data-sentry-element="RowSkeleton" data-sentry-source-file="index.tsx" />
          </th>
        </tr>
      </thead>
      <tbody className="before:block before:h-4">
        <tr className="border-b border-b-gray-100 px-4 last:border-none">
          <td className="w-1/4 rounded-tl-lg bg-gray-200 px-6 py-3.5">
            <RowWithImageSkeleton data-sentry-element="RowWithImageSkeleton" data-sentry-source-file="index.tsx" />
          </td>
          <td className="w-1/4 bg-gray-200 px-6">
            <RowSkeleton data-sentry-element="RowSkeleton" data-sentry-source-file="index.tsx" />
          </td>
          <td className="w-1/4 bg-gray-200 px-6">
            <RowSkeleton data-sentry-element="RowSkeleton" data-sentry-source-file="index.tsx" />
          </td>
          <td className="w-1/4 rounded-tr-lg bg-gray-200 px-6">
            <RowSkeleton className="justify-end" data-sentry-element="RowSkeleton" data-sentry-source-file="index.tsx" />
          </td>
        </tr>
        {[...Array(4)].map((_, i) => <tr key={i} className="border-b border-b-gray-100 px-4 last:border-none">
            <td className="w-1/4 bg-gray-200 px-6 py-3.5">
              <RowWithImageSkeleton />
            </td>
            <td className="w-1/4 bg-gray-200 px-6">
              <RowSkeleton />
            </td>
            <td className="w-1/4 bg-gray-200 px-6">
              <RowSkeleton />
            </td>
            <td className="w-1/4 bg-gray-200 px-6">
              <RowSkeleton className="justify-end" />
            </td>
          </tr>)}
        <tr className="border-b border-b-gray-100 px-4 last:border-none">
          <td className="w-1/4 rounded-bl-lg bg-gray-200 px-6 py-3.5">
            <RowWithImageSkeleton data-sentry-element="RowWithImageSkeleton" data-sentry-source-file="index.tsx" />
          </td>
          <td className="w-1/4 bg-gray-200 px-6">
            <RowSkeleton data-sentry-element="RowSkeleton" data-sentry-source-file="index.tsx" />
          </td>
          <td className="w-1/4 bg-gray-200 px-6">
            <RowSkeleton data-sentry-element="RowSkeleton" data-sentry-source-file="index.tsx" />
          </td>
          <td className="w-1/4 rounded-br-lg bg-gray-200 px-6">
            <RowSkeleton className="justify-end" data-sentry-element="RowSkeleton" data-sentry-source-file="index.tsx" />
          </td>
        </tr>
      </tbody>
    </table>;
};
export default DesktopTableSkeleton;