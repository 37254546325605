import useSWR from 'swr';

import { getCryptos } from '@/utils';
import { ROUTES } from '@/routes';

import { UseGetCryptosProps } from './types';

export const useGetCryptos = ({ lang, location, coins, filter }: UseGetCryptosProps) => {
  const response = useSWR(
    location?.result ? [ROUTES.APICryptos, lang, location.result.id, filter] : null,
    async () =>
      await getCryptos({
        coins: coins || [],
        location,
        filter,
        locale: lang,
      }),
    {
      refreshInterval: 10000,
      fallbackData: { result: null, isLoading: true },
    },
  );

  return response;
};
